<template lang="html">
  <section class="v-buzon-cv">
    <DataTable
      :value="curriculums"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      :resizableColumns="true"
      columnResizeMode="fit"
      dataKey="xid"
      :rowHover="true"
      :selection.sync="selectedCurriculums"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    >
      <template #header>
        <div class="table-header">
          Lista de curriculums
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global']"
              placeholder="Búsqueda global"
            />
          </span>
        </div>
      </template>
      <!--XID COLUMN-->
      <Column field="xid" header="Id" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Id</span>
          {{ slotProps.data.xid }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xid']"
            class="p-column-filter"
            placeholder="ID"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XEMAIL COLUMN-->
      <Column field="xemail" header="Email" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Email</span>
          {{ slotProps.data.xemail }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xemail']"
            class="p-column-filter"
            placeholder="Email"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XNOMBRE COLUMN-->
      <Column field="xnombre" header="Nombre" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Nombre</span>
          {{ slotProps.data.xnombre }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xnombre']"
            class="p-column-filter"
            placeholder="Nombre"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XAPELLIDO COLUMN-->
      <Column field="xapellido" header="Apellido" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Apellido</span>
          {{ slotProps.data.xapellido }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xapellido']"
            class="p-column-filter"
            placeholder="Apellido"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XTELEFONO COLUMN-->
      <Column field="xtelefono" header="Teléfono" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Teléfono</span>
          {{ slotProps.data.xtelefono }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xtelefono']"
            class="p-column-filter"
            placeholder="Teléfono"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XASUNTO COLUMN-->
      <Column field="xasunto" header="Asunto" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Asunto</span>
          {{ slotProps.data.xasunto }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xasunto']"
            class="p-column-filter"
            placeholder="Asunto"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XPUESTOS COLUMN-->
      <Column field="xpuestos" header="Puestos" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Puestos</span>
          {{ slotProps.data.xpuestos }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xpuestos']"
            class="p-column-filter"
            placeholder="Puestos"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XFECHA COLUMN-->
      <Column field="xfecha" header="Fecha" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Fecha</span>
          {{ slotProps.data.xfecha.split("T")[0] }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xfecha']"
            class="p-column-filter"
            placeholder="Fecha"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--BOTONES COLUMN-->
      <Column
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <vs-button
            icon
            color="rgb(109, 207, 195)"
            @click="verCV(slotProps.data)"
          >
            <i class="pi pi-eye"></i>
          </vs-button>
          <vs-button
            icon
            color="danger"
            @click="borrarDialog =true; cv = slotProps.data"
          >
            <i class="pi pi-trash"></i>
          </vs-button>
        </template>
      </Column>
      <Dialog
        :visible.sync="borrarDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar este CV? </span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="borrarDialog = false"
          />
          <Button
            label="Sí"
            icon="pi pi-check"
            class="p-button-danger"
            @click="borrarCV()"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="cvDialog"
        :header="'Curriculum de ' + cv.xnombre"
        :modal="true"
        class="p-fluid col-10"
        :baseZIndex="21001"
      >
        <div class="d-flex flex-wrap text-left text-xl p-5">
          <p class="col-6"><span>Nombre: </span>{{cv.xnombre}}</p>
          <p class="col-6"><span>Apellido: </span>{{cv.xapellido}}</p>
          <p class="col-6"><a :href="'tel:'+cv.xtelefono"><span>Teléfono: </span>{{cv.xtelefono}}</a></p>
          <p class="col-6"><a :href="'mailto:'+cv.xemail"><span>Email: </span>{{cv.xemail}}</a></p>
          <p class="col-12 col-md-7"><span>Puestos solicitados:</span>{{cv.xpuestos}}</p>
          <p class="col-12 col-md-5"><span>Fecha: </span>{{cv.xfecha}}</p>
          <p class="col-12">{{cv.xasunto}}</p>
          <p class="col-12">{{cv.xtexto}}</p>
          <vs-button v-if="cv.xcv" @click="descargarCV(cv.xcv)">
            <span class="text-lg"><i class="pi pi-download"></i>&nbsp;Descargar CV</span>
          </vs-button>
          <PSPDFKit :documentUrl="cv.xcv" v-if="cv.xcv"></PSPDFKit>
          
        </div>
      </Dialog>
    </DataTable>
  </section>
</template>

<script lang="js">
import API_URL from "../../backend";
import TrabajaNosotrosService from '../../services/trabajaNosotros.service'
import PSPDFKit from '../../components/BackOffice/PSPDFKit'
import ViewService from "../../services/view.service.js"

  export default  {
    name: 'v-buzon-cv',
    props: [],
    components:{
      PSPDFKit: PSPDFKit,
    },
    mounted () {
      this.cargarCVs()
    },
    data () {
      return {
        loader:null,
        curriculums:[],
        cv:{},
        cvDialog:false,
        filters: {},
        selectedCurriculums:[],
        API_URL:API_URL,
        borrarDialog:false,

      }
    },
    methods: {
      borrarCV(){
        TrabajaNosotrosService.borrar(this.cv.xid).then((response) => {
          this.$toast.add({
            severity:response.data.success ? 'info' : 'error',
            summary: response.data.success ? 'Eliminado': 'Error',
            detail: response.data.description,
            life: response.data.success ? 3000 : null
          });
          this.cargarCVs()
        })
      },
      cargarCVs(){
        ViewService.getDatosWeb().then((response)=>{
          const logo = response.data[0].xlogo;
          this.loader = this.$vs.loading({
          type: "square",
          color: "#f8b505",
        });
        setTimeout(() => {
          let loaderDIV = document.getElementsByClassName(
            "vs-loading__load__animation"
          )[0];
          loaderDIV.innerHTML = "";
          const imgLogo = document.createElement("img");
          imgLogo.src = API_URL+logo;
          imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";
          
          loaderDIV.addEventListener("mouseover", (e) => {
            e.target.style.width="300px";
          })
          loaderDIV.addEventListener("mouseout", (e) => {
            e.target.style.width="200px";
          })
          loaderDIV.appendChild(imgLogo);
        }, 5);
        });

        
        TrabajaNosotrosService.getTodos().then((response)=>{
          this.curriculums = response.data;
          this.loader.close();
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error.message
          });
          this.loader.close();
        })
      },
      verCV(cv){
        this.cv = cv;
        this.cvDialog = true;
        TrabajaNosotrosService.getDescargarCCVV(cv.xcv).then((response)=>{
          this.cv.url = window.URL.createObjectURL(new Blob([response.data]));
        })
      },
      descargarCV(cv){
        TrabajaNosotrosService.getDescargarCCVV(cv).then((response)=>{
         const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.cv.xcv);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      }
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.v-buzon-cv {
}
</style>
